<template>
  <v-container>
    <v-row class="title-row" align="middle" justify="center">
      <v-col cols="12">
        <span class="title" v-html="article.title" />
      </v-col>
    </v-row>
    <v-row class="authors-row">
      <v-col
        cols="6"
        v-for="(author, index) in article.authors"
        :key="index"
      >
        <v-card elevation="0">
          <b>{{ author.name }}</b><br>
          <template v-if="!author.universityNum.length">
            {{ article.universities[0].university }}
          </template>
          <template v-else>
            <span
              v-for="(university, index) in author.universityNum"
              :key="index"
            >
              {{ article.universities[university - 1].university }}
            </span>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="text-align: justify">
        <span v-html="article.text"></span>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        class="article-info-col"
      >
        <span v-html="article.keywords"></span>
      </v-col>
      <v-col
        cols="6"
        class="article-info-col"
      >
        <span v-html="article.history"></span>
      </v-col>
    </v-row>
    <v-row align="center" style="margin-bottom: 2%">
      <v-col cols="6">
        <b> {{article.DOI}} </b>
      </v-col>
      <v-col cols="6">
        <v-btn
            class="go-to-issue-card-action-button"
            color="teal accent-3"
            :href="getDoiLink(article.DOI)"
        >
          {{ $t('goToArticle') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FileLoader from "../utils/FileLoader";
export default {
  name: "article",
  data: () => ({
    articles: [],
    article: {}
  }),
  async mounted() {
    const doi = this.$route.query.doi
    const fileLoader = new FileLoader()
    this.articles = await fileLoader.Load('word.json')
    this.article = this.articles.find(art => art.DOI === `DOI: ${doi.trim()}`)
    console.dir({ articles: this.articles, article: this.article, DOI: `DOI: ${doi.trim()}` })
  },

  methods: {
    getDoiLink (doiString) {
      if (!doiString)
        return ''

      const doiNumber = doiString.split(':')[1]
      if (!doiNumber)
        return ''

      return 'https://doi.org/' + doiNumber.trim()
    }
  }
}
</script>

<style>
.title p {
  margin-bottom: 0;
}
.title{
  text-align: center;
}

.title-row {
  margin-top: 2%;
  margin-bottom: 2%;
}

.authors-row {
  margin-bottom: 2%;
  font-size: 19px !important;
}

.article-info-col span, .article-info-col p {
  font-size: 19px !important;
}

.article-info-col span p {
  margin-bottom: 0 !important;
}


.go-to-issue-card-action-button {
  padding: 15% 20% !important;
}
</style>
