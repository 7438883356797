<template>
  <main-body>
    <article-info />
  </main-body>
</template>

<script>
import mainBody from "../components/MainBody";
import articleInfo from "../components/ArticleInfo";
export default {
  name: "Article",
  components: { mainBody, articleInfo }
}
</script>

<style scoped>

</style>